export enum SortByEnum {
    REVENUE_INDIVIDUAL_VS_COMPANY = '0',
    REVENUE_SERVICE_REQUESTED = '1',
    TOTAL_REVENUE = '2',

  /*
  customer visitation report
  * */
  PARTIALLY_ASSISTED = '3',
  FULLY_ASSISTED = '4',
  NOT_ASSISTED = '5',
  ALL = '6',
  NEW = '7',
  INTERNAL = '8',
  EXTERNAL = '9',
  TOTAL_CUSTOMERS = '10',
  MOST_COMMODITY = '11',
  MOST_SERVICE_REQUEST = '12',
  MOST_SERVICE_CENTER = '13',
  ALL_CUSTOMERS = '14',

  INVENTORY_REPORT = '15',
  PRESENTATION_REPORT = '16',
  CATEGORY_REPORT = '17',
  TRANSACTION_REPORT = '18',
  STATUS_REPORT = '19',
    //sampling point report
  LOCATION = '20',
  COMPANY = '21',
  PROJECT = '22',
  DEPTH = '23',
  PERSONNEL = '24',
  BOREHOLE = '25',
  SAMPLE = '26',
  ELEMENT = '27',
  //ADMIN REPORTS
  ACTIVITY_PERFORMED = '28'
  }

export enum ReportTypeEnum {
  REVENUE_REPORT = '0',
  CUSTOMER_VISITATION_REPORT = '1',
  SERVICE_RATE_REPORT = '2',
  SPECIMEN_REPORT = '3',
  CORESHED_REPORT = '4',
  SAMPLING_POINT_REPORT = '5',
  STAFF_ENGAGEMENTS_REPORT = '6',
  ACTIVITY_LOG_REPORT = '7',
  DOWNHOLE_CHEMISTRY_REPORT = '8',
  MINERAL_CHEMISTRY_REPORT = '9',
  MRHC_SERVICE_RATE_REPORT = '10',
}
export enum InventoryFilterEnum {
  COLLECTION_TYPE = '0',
  SPECIMEN = '1',
  PERSONNEL = '2',
  DATE = '3',
}

export enum InventoryTypeFilterEnum {
  ROCK = 'ROCK',
  MINERAL = 'MINERAL',
  FOSSIL = 'FOSSIL',
  LIQUID = 'LIQUID',
  ORE= 'ORE',
  SEDIMENT = 'SEDIMENT',
}

export enum CoreshedFieldFilter {
  CHIP = '0',
  CORE = '1',
  RELOCATION = '2',
  LOANING = '3',
  DISPOSAL = '4',
}

export enum FieldPhrasesEnum{
  RELOCATION = 'Relocated Borehole Samples',
  LOANING = 'Loaned Borehole Samples',
  DISPOSAL = 'Disposed Borehole Samples',
}

export enum FieldNamesEnum{
  RELOCATION = 'Relocation',
  LOANING = 'Loaning',
  DISPOSAL = 'Disposal',
}



export enum ActionFilterEnum{
  add = 'Create',
  edit = 'Update',
  del = 'Deletion',
  appr = 'Approval',
  rej = 'Reject',
}

