import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ServiceEndPoints} from '../../shared-kernel/apiCalls/ServiceEndPoints';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Person } from 'src/app/_core/data/_models/people.model';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesSettingsService implements OnInit {
  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
  }

  getAllSampleTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities-settings/v1/sample-type/all');
  }

  createSampleType(sampleType) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities-settings/v1/sample-type/create',sampleType);
  }

}

