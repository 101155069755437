import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UserRole, Authority, AuthorityGroup } from '../_models';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { reduce } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthoritiesService {
    constructor(
        private http: HttpClient
    ) {}

    newAuthority(authority: Authority): Observable<Authority> {
        // return this.http.post<Authority>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.create, authority);
        throw new Error("NotImplemented");
    }

    getAuthorities(): Observable<Array<Authority>> {
        return this.http.get<Array<Authority>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllAuthoritiesUrl);
    }

    getAuthorityGroups(): Observable<Array<AuthorityGroup>> {
        return this.http.get<Array<AuthorityGroup>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllAuthorityGroups);
    }

    createAuthorityGroup(authorityGroup: AuthorityGroup): Observable<AuthorityGroup> {
        return this.http.post<AuthorityGroup>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.createAuthorityGroup, authorityGroup);
    }

    getAuthorityGroupById(groupId: string): Observable<AuthorityGroup> {
        return this.http.get<AuthorityGroup>(ServiceEndPoints.authBaseUrl + `/api/v1/authority-group/one/${groupId}`);
    }
}