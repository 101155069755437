import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UserRole } from '../_models';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';

@Injectable({
    providedIn: 'root'
})
export class UserRolesService {
    constructor(
        private http: HttpClient
    ) {}

    newUserRole(role: UserRole): Observable<UserRole> {
        return this.http.post<UserRole>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.createNewRoleUrl, role);
    }

    getRoles(): Observable<Array<UserRole>> {
        return this.http.get<Array<UserRole>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllRolesUrl);
    }

    getUserRoleById(userRoleId: string): Observable<UserRole> {
        return this.http.get<UserRole>(ServiceEndPoints.authBaseUrl + `/api/role/v1/role/${userRoleId}`);
    }

    getUserByUsername(username: string): Observable<any> {
        return this.http.get(ServiceEndPoints.authBaseUrl + `/api/user/v1/dto/user/${username}`)
    }

    addUserRoles(userId: string, roles: Array<UserRole>): Observable<any> {
        return this.http.patch<any>(ServiceEndPoints.authBaseUrl + `/api/user/v1/${userId}/roles`, roles);
    }
}